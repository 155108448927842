<template>
  <div class="page-aside">
    <slot />
    <div class="support">
      <p class="tag">参与支持</p>
      <div class="linkNav"><div class="tagLink"></div></div>
      <a class="picTop"
        ><img @click="toOnline" src="@/assets/images/jzck.png"
      /></a>
      <a
        ><img class="query" @click="toQuery" src="@/assets/images/jzcx.png"
      /></a>
    </div>
  </div>
</template> 

<script>
export default {
  name: "pageAside",
  data() {
    return {
      activeIndex: "0",
    };
  },
  mounted() {},
  methods: {
    // 点击捐赠窗口 跳转到 在线捐赠
    toOnline() {
      this.$router.push("/donation/online");
    },
    // 点击捐赠查询 跳转到 捐赠查询
    toQuery() {
      this.$router.push("/donation/query");
    },
  },
};
</script>

<style lang='scss' scoped>
.page-aside {
  ::v-deep {
    .el-menu {
      border: none;
    }
    .el-menu-item {
      font-size: 18px;
      text-align: center;
      &.is-active {
        color: $red;
        border-left: 3px solid $red;
      }
      &:focus,
      &:hover {
        background-color: #fff;
      }
    }
  }
}
.support {
  .tag {
    display: block;
    margin-top: 59px;
    margin-left: 5px;
    font-size: 18px;
    color: #c40000;
  }
  .tagLink {
    width: 112px;
    border-top: solid #c40000 1px;
  }
  .linkNav {
    width: 100%;
    border-top: solid #e0e0e0 1px;
    margin-bottom: 25px;
    margin-top: 9px;
  }

  .picTop {
    display: block;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .query {
    margin-bottom: 20px;
  }
}
</style>