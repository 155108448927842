<template>
  <div class="main-wrap">
    <page-bread />
    <el-container class="home-container">
      <el-aside width="292px">
        <page-aside>
          <el-menu :default-active="activeIndex" router>
            <el-menu-item
              :index="item.path"
              v-for="item in menuList"
              :key="item.path"
              >{{ item.name }}</el-menu-item
            >
          </el-menu>
        </page-aside>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";
export default {
  name: "news",
  data() {
    return {
      menuList: [
        {
          name: "时政要闻",
          path: "/news/currentNews",
        },
        {
          name: "司法行政要闻",
          path: "/news/judicial",
        },
        {
          name: "本会动态",
          path: "/news/dynamic",
        },
      ],
    };
  },
  computed: {
    activeIndex:function () {
      return this.menuList.find(item => this.$route.path.includes(item.path)).path
    }
  },
  mounted() {},
  components: {
    pageAside,
  },
  methods: {},
};
</script>

<style lang='scss' scoped>
.el-main {
  overflow-x: hidden;
  border-left: 8px solid #f4f4f4;
  padding: 0px;
}
</style>